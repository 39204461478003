import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableContainer,
  TextField,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';

import { TableBodyCard, FilterArea } from './OrdersList.styled';
import IPage from '../../interfaces/IPage';
import { IOrder } from '../../interfaces/IOrder';
import { useToastr } from '../../hooks/useToastr';
import { PageCard } from '../../components/page-card/PageCard';
import { useOrderService } from '../../services/useOrderService';
import { TitleContainer } from '../../components/title-container/TitleContainer';
import CustomTablePagination from '../../components/table/CustomTablePagination/CustomTablePagination';
import { ButtonGroup } from '../../components/button-group/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import { IEvent } from '../../interfaces/IEvent';
import OrderCard from './order-card/OrderCard';
import { useAuth } from '../../hooks/auth';
import { useOrderStatusService } from '../../services/useOrderStatusService';
import { IRequestPostParams } from '../../interfaces/IRequestPostParams';
import OrderConfirmDialog from './order-confirm-dialog/OrderConfirmDialog';
import dayjs, { Dayjs } from 'dayjs';
import OrderCloneDialog from './order-clone-dialog/OrderConfirmDialog';
import { BackdropCustom } from '../../components/backdrop/Backdrop';
import { useOrderParamsService } from '../../services/useOrderParamsService';

const fields = [
  {
    field: 'orderNumber',
    label: 'Número do Pedido',
  },
  {
    field: 'customerCode',
    label: 'Cód. Cliente',
  },
  {
    field: 'customerTradeName',
    label: 'Cliente',
  },
  {
    field: 'sellerCode',
    label: 'Cód. Representante',
  },
  {
    field: 'sellerName',
    label: 'Representante',
  },
  {
    field: 'hubSpotId',
    label: 'ID Negociação HubSpot',
  },
];

interface IParams {
  perPage: number;
  currentPage: number;
  orderField: string;
  orderDirection: 'asc' | 'desc';
  field?: string;
  value?: string;
  precision?: string;
  delay?: number;
}

interface IOrderStatus {
  id: number;
  description: string;
}

const OrdersList: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const { listAll, cloneOrder } = useOrderService();
  const { list } = useOrderParamsService();
  const toastr = useToastr();
  const timeout = useRef<any>(null);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [filterAreaOpen, setFilterAreaOpen] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDirection] = useState<'asc' | 'desc'>('desc');
  const [orderField, setOrderField] = useState<string>('orderNumber');
  const [loading, setLoading] = useState(false);
  const [clonedOrderId, setClonedOrderId] = useState('');

  const [filterField, setFilterField] = useState<string>('sellerCode');
  const [filterPrecision, setFilterPrecision] = useState<string>('equal');

  const [orderStatus, setOrderStatus] = useState<IOrderStatus[] | []>([]);
  const [selectedOrderStatus, setSelectedValues] = useState<IOrderStatus[]>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isCloneDialogOpen, setIsCloneDialogOpen] = useState(false);
  const [initialDate, setInitialDate] = useState<Dayjs | null>(null);
  const [finalDate, setFinalDate] = useState<Dayjs | null>(null);
  const [originalOrderId, setOriginalOrderId] = useState<string | null>(null);

  const { listOrderStatus } = useOrderStatusService();

  const { state } = useAuth();

  const [filterValue, setFilterValue] = useState<string>(() => {
    const seller = state.seller;
    if (seller && Number(seller.code) > 0) {
      return seller.code;
    } else {
      return '';
    }
  });
  const [delay, setDelay] = useState(0);

  const navigate = useNavigate();

  const handlePagination = async (
    perPage: number,
    currentPage: number,
    orderField: string,
    orderDirection: 'asc' | 'desc',
  ) => {
    handleListAll({
      perPage,
      currentPage,
      orderField,
      orderDirection,
      field: filterField,
      value: filterValue,
      precision: filterPrecision,
      delay: 0,
    });
  };

  useEffect(() => {
    if (state.seller?.code) {
      setFilterField('sellerCode');
      setFilterPrecision('equal');
      setFilterValue(state.seller?.code);
      handleListAll({
        perPage,
        currentPage,
        orderField,
        orderDirection,
        field: 'sellerCode',
        value: state.seller?.code,
        precision: 'equal',
        delay,
      });
    }
  }, [state.seller?.code]);

  const handleToEdit = useCallback(
    (id: string | undefined) => {
      !!id ? navigate(`/order/${id}/edit`) : navigate('/order/steper/0}/new');
    },
    [navigate],
  );

  const handleListAll = useCallback(
    async ({ perPage, currentPage, orderField, field, value, precision, delay }: IParams) => {
      const filter: IRequestPostParams = {
        perPage,
        currentPage: currentPage + 1,
        orderBy: orderField,
        orderDirection: 'desc',
        filterField: `${field}`,
        filterValue: `${value}`,
        precision: `${precision}`,
        status: selectedOrderStatus.map(s => s.id),
        initialDate: initialDate?.format('YYYY-MM-DD') || null,
        finalDate: finalDate?.format('YYYY-MM-DD') || null,
      };

      clearInterval(timeout.current);
      timeout.current = setTimeout(async () => {
        setLoading(true);
        await listAll(filter)
          .then(response => {
            if (response?.data?.length > 0) {
              setOrders(response.data);
              setTotalRows(response.totalRows);
              setLoading(false);
            } else {
              setOrders([]);
              setTotalRows(0);
              setPerPage(10);
              setCurrentPage(0);
              setLoading(false);
            }
          })
          .catch(error => {
            toastr.error(error?.message || 'Contate a equipe de suporte');
          })
          .finally(() => {
            setLoading(false);
          });
      }, delay || 0);
    },
    [selectedOrderStatus, initialDate, finalDate, timeout],
  );

  const handleReturnError = (events: IEvent[] | undefined): string => {
    if (events) {
      const eventsSorted = events.sort((a, b) => {
        if (a.created_at < b.created_at) {
          return -1;
        }
        if (a.created_at > b.created_at) {
          return 1;
        }
        return 0;
      });

      const lestEvent = eventsSorted[eventsSorted.length - 1];

      return lestEvent.message;
    } else {
      return '';
    }
  };

  useEffect(() => {
    setCurrentPage(0);
    handlePagination(perPage, 0, orderField, 'asc');
  }, [orderField, selectedOrderStatus, initialDate, finalDate]);

  useEffect(() => {
    const field = filterField;
    const value = filterValue;
    const precision = filterPrecision;

    handleListAll({ perPage, currentPage, orderField, orderDirection, field, value, precision, delay });
  }, [filterField, filterPrecision, filterValue]);

  const handleTextFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
    setTotalRows(0);
    setCurrentPage(0);
    setDelay(500);
  };

  const handleViewOrder = useCallback(async (orderId: string | undefined) => {
    if (orderId && orderId.length > 15) {
      const token = btoa(state.tenantDomain);

      const orderParams = await list();

      if (orderParams.reportDefaultFormat === 'PDF') {
        window.open(
          `${process.env.REACT_APP_BASE_FRONTEND_URL}/#/order/${orderId}/report/pdf?token=${token}`,
          '_blank',
        );
      } else {
        window.open(`${process.env.REACT_APP_BASE_FRONTEND_URL}/#/order/${orderId}/report?token=${token}`, '_blank');
      }
    }
  }, []);

  const handleChangeOrderStatus = (event: React.ChangeEvent<{}>, values: IOrderStatus[]) => {
    setSelectedValues(values);
  };

  const handleListOrderStatus = async () => {
    setLoading(true);
    await listOrderStatus(`perPage=100&currentPage=1&orderBy=id&orderDirection=asc`)
      .then(response => {
        const responseOrderStatus = response.data.map(status => {
          return { id: status.id, description: status.description ? status.description : '' };
        });

        setOrderStatus(responseOrderStatus);
      })
      .catch(error => {
        toastr.error(error?.message || 'Contate a equipe de suporte');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
    navigate(`/order/${clonedOrderId}/edit`);
  };

  const handleDenyDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  useEffect(() => {
    handleListOrderStatus();
  }, []);

  const handleCloneOrder = async (orderId: string | undefined, keepItems: boolean): Promise<void> => {
    setIsCloneDialogOpen(false);
    setLoading(true);
    await cloneOrder(`${orderId}`, keepItems)
      .then(newOrder => {
        setClonedOrderId(`${newOrder?.id}`);
        setIsConfirmDialogOpen(true);
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    await handleListAll({
      perPage,
      currentPage,
      orderField,
      orderDirection,
      field: filterField,
      value: filterValue,
      precision: filterPrecision,
      delay,
    });
  };

  return (
    <>
      <TitleContainer>
        <h1>Pedidos</h1>
      </TitleContainer>
      <PageCard>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '10px 0px 10px 10px',
          }}
        >
          <ButtonGroup>
            <IconButton
              onClick={() => navigate('/order/steper/0/new')}
              size="small"
              color="primary"
              style={{ alignItems: 'flex-end' }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              onClick={() => handlePagination(perPage, currentPage, orderField, orderDirection)}
              size="small"
              color="primary"
              style={{ alignItems: 'flex-end' }}
            >
              <RefreshIcon />
            </IconButton>
            <IconButton
              onClick={() => setFilterAreaOpen(!filterAreaOpen)}
              size="small"
              color="primary"
              style={{ alignItems: 'flex-end' }}
            >
              {filterAreaOpen ? <FilterAltOffOutlinedIcon /> : <FilterAltOutlinedIcon />}
            </IconButton>
          </ButtonGroup>
        </div>
        {filterAreaOpen && (
          <FilterArea container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="select-filter">Filtrar por</InputLabel>
                <Select
                  label="Filtrar Por"
                  value={`${filterField}`}
                  onChange={e => {
                    setFilterField(`${e.target.value}`);
                  }}
                >
                  {fields.map(filter => {
                    return (
                      <MenuItem key={filter.field} value={filter.field}>
                        {filter.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel>Precisão</InputLabel>
                <Select
                  label="Precisão"
                  value={`${filterPrecision}`}
                  onChange={e => {
                    setFilterPrecision(`${e.target.value}`);
                  }}
                >
                  <MenuItem key={'equal'} value={'equal'}>
                    Igual
                  </MenuItem>
                  <MenuItem key={'containing'} value={'containing'}>
                    Contendo
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Valor a filtrar"
                placeholder="Valor a filtrar"
                value={filterValue}
                onChange={handleTextFilterChange}
                size="small"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                style={{ width: '100%' }}
                multiple
                id="tags-outlined"
                options={orderStatus}
                getOptionLabel={option => option.description}
                filterSelectedOptions
                size="small"
                onChange={handleChangeOrderStatus}
                renderInput={params => {
                  return <TextField {...params} label="Status do pedido" />;
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                  slotProps={{ textField: { size: 'small', fullWidth: true } }}
                  label="Data Inicial"
                  value={initialDate}
                  onChange={value => setInitialDate(dayjs(String(value)))}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <DatePicker
                  slotProps={{ textField: { size: 'small', fullWidth: true } }}
                  label="Data Final"
                  value={finalDate}
                  onChange={value => setFinalDate(dayjs(String(value)))}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Ordenar por</InputLabel>
                <Select
                  value={orderField}
                  onChange={e => {
                    setOrderField(`${e.target.value}`);
                  }}
                  label="Ordenar por"
                >
                  <MenuItem key="orderNumber" value="orderNumber">
                    Status e Número do Pedido
                  </MenuItem>
                  <MenuItem key="orderDate  " value="orderDate">
                    Status e Data Emissão
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </FilterArea>
        )}

        <TableContainer>
          <TableBodyCard>
            {orders &&
              orders.map(order => {
                return (
                  <OrderCard
                    key={order.id}
                    order={order}
                    handleReturnError={handleReturnError}
                    handleViewOrder={handleViewOrder}
                    handleToEdit={handleToEdit}
                    handleCloneOrder={() => {
                      if (order.id) {
                        setOriginalOrderId(order.id);
                        setIsCloneDialogOpen(true);
                      }
                    }}
                  />
                );
              })}
          </TableBodyCard>
        </TableContainer>
        <CustomTablePagination
          totalRows={totalRows}
          currentPage={currentPage}
          perPage={perPage}
          handleRequest={handlePagination}
          setPerPage={setPerPage}
          setCurrentPage={setCurrentPage}
          orderField={orderField}
          orderDirection={orderDirection}
        />
      </PageCard>

      {loading && <BackdropCustom />}
      <OrderConfirmDialog open={isConfirmDialogOpen} confirm={handleConfirmDialog} deny={handleDenyDialog} />
      {originalOrderId && (
        <OrderCloneDialog
          orderId={originalOrderId}
          open={isCloneDialogOpen}
          cloneOrder={handleCloneOrder}
          deny={() => {
            setIsCloneDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export { OrdersList };
