import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '../../../components/button/Button';
import { Checkbox, FormControlLabel } from '@mui/material';

interface IProps {
  orderId: string;
  open: boolean;
  deny: () => void;
  cloneOrder: (id: string, keepItems: boolean) => void;
}

const OrderCloneDialog = ({ orderId, open, cloneOrder, deny }: IProps) => {
  const [keepItems, setKeepItems] = React.useState(true);

  return (
    <Dialog open={open}>
      <DialogContent sx={{ width: '250px' }}>
        <DialogContentText id="alert-dialog-description">Deseja realmente duplicar o pedido?</DialogContentText>

        <FormControlLabel
          control={
            <Checkbox
              checked={keepItems}
              onClick={() => {
                setKeepItems(!keepItems);
              }}
            />
          }
          label="Manter os itens"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={deny} color="error">
          Cancelar
        </Button>
        <Button onClick={() => cloneOrder(orderId, keepItems)} autoFocus>
          Duplicar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderCloneDialog;
