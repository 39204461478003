import React, { useEffect, useRef, useState } from 'react';
import IPage from '../../interfaces/IPage';
import OrderDataForm from './OrderDataForm';
import OrderItemList from './OrderItemList';
import OrderCustomerForm from './OrderCustomerForm';
import { ButtonContainer, OrderFormContainer } from './OrderForm.styled';
import { Button } from '../../components/button/Button';
import IOrderDataFormProps from './interfaces/IOrderDataFormProps';
import IOrderItemListProps from './interfaces/IOrderItemListProps';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useOrderService } from '../../services/useOrderService';
import { IOrder } from '../../interfaces/IOrder';
import { useToastr } from '../../hooks/useToastr';
import { IOrderItem } from '../../interfaces/IOrderItem';
import { useOrderItemService } from '../../services/useOrderItemService';
import IOrderCustomerFormProps from './interfaces/IOrderCustomerFormProps';
import { useAuth } from '../../hooks/auth';
import { useHubSpotService } from '../../services/useHubSpotService';
import { useOrderParamsService } from '../../services/useOrderParamsService';
import { BackdropCustom } from '../../components/backdrop/Backdrop';

const OrderForm: React.FC<IPage> = ({ title }) => {
  window.document.title = title;

  const { state } = useAuth();

  const orderDataForm = useRef<IOrderDataFormProps>(null);
  const orderItemList = useRef<IOrderItemListProps>(null);
  const orderCustomerForm = useRef<IOrderCustomerFormProps>(null);
  const [order, setOrder] = useState<IOrder | null>(null);
  const [, setItems] = useState<IOrderItem[]>([]);
  const [perPage] = useState(200);
  const [currentPage] = useState(1);
  const [orderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderField] = useState<string>('sequence');
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [cancelOrderDialogOpen, setCancelOrderDialogOpen] = useState(false);
  const [integrateHubSpot, setIntegrateHubSpot] = useState(false);

  const params = useParams();
  const orderService = useOrderService();
  const orderItemService = useOrderItemService();
  const toastr = useToastr();
  const hubSpotService = useHubSpotService();
  const orderParamsService = useOrderParamsService();

  const navigate = useNavigate();

  const closeOrder = async (): Promise<void> => {
    if (order?.customer?.isProspect) {
      return;
    }
    setLoading(true);
    const localItems = await handleListItems();

    const thereAreItemsWithErrors = localItems && localItems.filter(item => item.status?.id !== 2)?.length !== 0;
    const thereAreItems = localItems && localItems.length !== 0;

    if (thereAreItemsWithErrors) {
      setLoading(false);
      toastr.error('Existem itens com erros de cálculo.');
      return;
    }
    if (!thereAreItems) {
      setLoading(false);
      toastr.error('Não é possível finalizar pedido sem itens.');
      return;
    }

    if (order) {
      await orderService
        .updateOrder(`${order.id}`, {
          id: order.id,
          orderNumber: order.orderNumber,
          branchOfficeId: (order.branchOffice?.id && order.branchOffice?.id) || '',
          orderTypeId: order.orderTypeId,
          customerId: order.customerId,
          sellerId: order.sellerId,
          orderStatusId: 2,
        })
        .then(async () => {
          handleSendDataToHubSpot('CLOSE');
          await listOrder();
          await orderDataForm.current?.listOrder(`${params.id}`);
          await orderItemList.current?.listOrder();

          toastr.success('Pedido fechado com sucesso.');
        })
        .catch(error => {
          toastr.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleOpenOrder = async (): Promise<void> => {
    const orderTemp = await listOrder();

    if (parseInt(`${orderTemp?.orderNumber}`) > 0) {
      await orderDataForm.current?.listOrder(`${params.id}`);
      setCancelOrderDialogOpen(true);
    } else {
      await openOrder();
    }
  };

  const openOrder = async () => {
    setLoading(true);
    setCancelOrderDialogOpen(false);
    await orderService
      .openOrder(`${params.id}`)
      .then(async () => {
        await listOrder();
        await orderDataForm.current?.listOrder(`${params.id}`);
        await orderItemList.current?.listOrder();

        toastr.success('Pedido reaberto  com sucesso.');
      })
      .catch(error => {
        toastr.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleListItems = async (): Promise<IOrderItem[] | void> => {
    return await orderItemService
      .listItemsByOrderId(
        `${params.id}`,
        `?perPage=${perPage}&currentPage=${currentPage}&orderBy=${orderField}&orderDirection=${orderDirection}`,
      )
      .then(response => {
        setItems(response.data);
        return response.data;
      })
      .catch(error => {});
  };

  const listOrder = async (): Promise<IOrder | void> => {
    return await orderService
      .listOrder(`${params.id}`, true)
      .then(order => {
        order && setOrder(order);
        return order;
      })
      .catch(error => {
        toastr.error(error.message);
      });
  };

  const handleSendDataToHubSpot = (event: 'CLOSE' | 'SAVE') => {
    if (integrateHubSpot && params.id) {
      hubSpotService.updateAmountInHubSpot(params.id).catch(error => {
        toastr.error(error.message || 'Erro ao atualizar valor do pedido no HUBSpot. Contate o suporte.');
      });

      hubSpotService.updateDealStageInHubSpot(params.id, event).catch(error => {
        toastr.error(error.message || 'Erro ao atualizar estágio da negociação no HUBSpot. Contate o suporte.');
      });
    }
  };

  const handleSaveOrderData = async () => {
    const localItems = await handleListItems();

    const thereAreItemsWithErrors = localItems && localItems.filter(item => item.status?.id !== 2)?.length !== 0;

    if (thereAreItemsWithErrors) {
      setLoading(false);
      toastr.error('Existem itens sendo calculados ou com erro de calculo. Verifique e tente novamente.');
      return;
    }

    await orderDataForm.current?.save();
    await listOrder();

    handleSendDataToHubSpot('SAVE');
  };

  const handleItemsUpdated = async () => {
    orderDataForm.current?.listOrder(`${params.id}`);
    orderCustomerForm.current?.listOrder(`${params.id}`);
    await listOrder();
  };

  const handleDeleteOrder = async (id: string | undefined) => {
    setDeleteLoading(true);

    if (id) {
      await orderService
        .deleteOrder(`${params.id}`)
        .then(() => {
          toastr.success('Pedido deletado com sucesso.');
          navigate('/orders-list');
        })
        .catch(error => {
          toastr.error(error.message);
        })
        .finally(() => {
          setDeleteLoading(false);
        });
    }
  };

  const isEditionDisabled = () => {
    return order?.orderStatus?.id !== 1 || loading || Number(order?.orderNumber) > 0;
  };
  const isCloseDisabled = () => {
    return (
      order?.orderStatus?.id !== 1 ||
      loading ||
      Number(order?.orderNumber) > 0 ||
      order?.customer?.isProspect ||
      order?.isDraft
    );
  };
  const isReopenDisabled = () => {
    return (order?.orderStatus?.id !== 2 && order?.orderStatus?.id !== 11 && order?.orderStatus?.id !== 9) || loading;
  };

  useEffect(() => {
    listOrder();
    handleListItems();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [params.id]);

  const listOrderParams = async () => {
    await orderParamsService
      .list()
      .then(param => {
        setIntegrateHubSpot(param.integrateHubSpot || false);
      })
      .catch(() => {
        toastr.error('Erro ao carregar informações. Contate o suporte');
      });
  };

  useEffect(() => {
    listOrderParams();
  }, []);

  return (
    <OrderFormContainer>
      {loading && <BackdropCustom />}
      <OrderCustomerForm ref={orderCustomerForm} />
      <OrderDataForm ref={orderDataForm} />
      <ButtonContainer>
        <div className="button-print">
          <Button
            variant="contained"
            disabled={loading || order?.isDraft}
            onClick={() => {
              const token = btoa(state.tenantDomain);
              window.open(
                `${process.env.REACT_APP_BASE_FRONTEND_URL}/#/order/${params.id}/report/pdf?token=${token}`,
                '_blank',
              );
            }}
            style={{ minWidth: '130px' }}
          >
            Imprimir
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            onClick={() => navigate('/orders-list')}
            color="inherit"
            style={{ minWidth: '130px' }}
          >
            Voltar
          </Button>
        </div>
        <div className="buttons-actions">
          <Button
            variant="contained"
            onClick={closeOrder}
            disabled={isCloseDisabled()}
            color="success"
            style={{ minWidth: '130px' }}
            sx={{ marginLeft: '10px' }}
            title="Após a conclusão do pedido, ele será encaminhado automaticamente para ERP"
          >
            Fechar Pedido
          </Button>
          <Button
            variant="contained"
            onClick={handleOpenOrder}
            disabled={isReopenDisabled()}
            color="inherit"
            style={{ minWidth: '130px' }}
            sx={{ marginLeft: '10px' }}
            title="Caso o pedido já tenha integração ele será cancelado no ERP."
          >
            Reabrir Pedido
          </Button>
          <Button
            variant="contained"
            onClick={() => setDeleteDialogOpen(!deleteDialogOpen)}
            color="error"
            style={{ minWidth: '130px' }}
            sx={{ marginLeft: '10px' }}
            disabled={isEditionDisabled()}
          >
            Excluir Pedido
          </Button>
          <Button
            variant="contained"
            onClick={() => orderItemList.current?.addItem()}
            disabled={isEditionDisabled()}
            style={{ minWidth: '130px' }}
            sx={{ marginLeft: '10px' }}
          >
            Adicionar Itens
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveOrderData}
            disabled={isEditionDisabled()}
            style={{ minWidth: '130px' }}
            title="Salva as informações alteradas nos dados gerais do pedido"
          >
            Salvar
          </Button>
        </div>
      </ButtonContainer>
      <hr />
      <OrderItemList ref={orderItemList} itemsUpdated={handleItemsUpdated} />

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(!deleteDialogOpen);
        }}
        sx={{
          '& .MuiDialog-container': {
            width: '100vw',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <DialogContent>
          <DialogContentText sx={{ marginTop: '10px', marginBottom: '-10px' }}>
            Deseja realmente deletar o pedido?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ marginRight: '10px' }}>
          <Button
            color="error"
            onClick={() => {
              setDeleteDialogOpen(!deleteDialogOpen);
            }}
            disabled={deleteLoading}
          >
            Cancelar
          </Button>
          <Button
            defaultColor={true}
            variant="text"
            onClick={async () => {
              await handleDeleteOrder(order?.id);
              setDeleteDialogOpen(!deleteDialogOpen);
            }}
            autoFocus
            loading={deleteLoading}
            disabled={deleteLoading}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={cancelOrderDialogOpen} maxWidth="md">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Pedido já gerado no ERP. Caso confirme o pedido do ERP de número {order?.orderNumber} será cancelado.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setCancelOrderDialogOpen(false)}>
            Cancelar
          </Button>
          <Button autoFocus onClick={openOrder}>
            Reabir Pedido
          </Button>
        </DialogActions>
      </Dialog>
    </OrderFormContainer>
  );
};

export default OrderForm;
