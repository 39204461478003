import AppError from '../errors/AppError';
import { IPaginationReturn } from '../interfaces/IPaginationReturn';
import { IProduct } from '../interfaces/IProduct';

import { useRequest } from './useRequest';

const useProductService = () => {
  const request = useRequest<IProduct>();
  const requestStr = useRequest<string>();
  const path = 'products';

  const listAll = async (queryParams: string): Promise<IPaginationReturn<IProduct[]>> => {
    return await request
      .getManyPaginated({ path: `${path}?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const listDynamically = async (queryParams: string): Promise<IPaginationReturn<IProduct[]>> => {
    return await request
      .getManyPaginated({ path: `${path}/dynamically?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const listProductsSkus = async (queryParams: string): Promise<IPaginationReturn<string[]>> => {
    return await requestStr
      .getManyPaginated({ path: `${path}/list-skus?${queryParams}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  const listProductsBySku = async (sku: string): Promise<IProduct[]> => {
    return await request
      .getMany({ path: `${path}/by-sku/${sku}`, sendAuthorization: true })
      .then(result => {
        return result;
      })
      .catch(error => {
        throw new AppError(error.message);
      });
  };

  return { listAll, listDynamically, listProductsSkus, listProductsBySku };
};

export { useProductService };
